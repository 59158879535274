import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';  
import CONFIG from '../config.js';

const baseQuery = fetchBaseQuery({  
  baseUrl: CONFIG.BASE_URL, 
});  
  
export const queryService = createApi({  
  reducerPath: 'queryServiceApi',  
  baseQuery,  
  endpoints: (builder) => ({  
    // Define your endpoints here  
    getQueryResponse: builder.mutation({  
      query: ({chat_session_id, query_id, query, signal}) => ({
        url: 'api/v2/query',
        method: 'POST',
        body: {chat_session_id, query_id, query},
        signal
      })
    }),  
    getFile: builder.query({
      query: (document_id) => ({
        url: `api/v1/knowledge-base/${document_id}?mode=download`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response, meta) => {  
        // Return a serializable object containing the blob and content-type  
        return {  
          blob: response,  
          contentType: meta.response.headers.get('content-type'),  
        };  
      },  
    }),
    // getPdf: builder.query({
    //   query: (documentId) => ({
    //     url: `/api/v1/knowledge-base/${documentId}?mode=view`,
    //     responseHandler: async (response) => {
    //       if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }
    //       const blob = await response.blob();
    //       return URL.createObjectURL(blob); // Convert blob to object URL
    //     },
    //   }),
    // }),
    getPdf: builder.query({
      query: ({documentId, queryId}) => {
        console.log('useGetPdfQuery queryId', queryId)
        return {
        url: `/api/v1/knowledge-base/${documentId}?mode=view-highlights&query_id=${queryId}`,
        // responseHandler: async (response) => {
        //   if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        //   }
        //   const blob = await response.blob();
        //   return URL.createObjectURL(blob); // Convert blob to object URL
        // },
      }},
      transformResponse: (response) => {
        // Extract base64-encoded PDF and highlights from the response
        const { pdf, highlightSegments } = response;
    
        // Decode Base64 string and create a Blob for the PDF
        const binaryString = atob(pdf); // Decode Base64 string to binary data
        const binaryData = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
        const pdfBlob = new Blob([binaryData], { type: 'application/pdf' });
    
        // Create an Object URL for the PDF Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        // Return both the PDF URL and highlights
        return { pdfUrl, highlightSegments };
      },
    }),
  }),  
});  
  
export const { useGetQueryResponseMutation, useLazyGetFileQuery, useGetPdfQuery  } = queryService; 

