import React, {useState} from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, AppBar, Toolbar, Typography, IconButton, Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  Divider
 } from "@mui/material";
 import CloseIcon from '@mui/icons-material/Close';
import Sidebar from "../Sidebar/Sidebar.jsx";
import { useTheme } from "@emotion/react";
import { useSelector, useDispatch } from 'react-redux'; 
import {setActiveClient, resetSession} from "../../app/slices/sessionSlice";

import { NotificationDialog, styles } from "./styles.js"

import {ReactComponent as EYLogo} from '../../assets/images/ey-logo.svg';
import {ReactComponent as BellIcon} from '../../assets/images/bell.svg';
import theme from "../../constants/theme.js";

const Header = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const activeClient = useSelector(state => state.session.active_client);
  console.log('activeClient', activeClient)
  const path = location.pathname;
  const getHeaderTitle = () => {
    if (path.includes("/knowledge-base")) return "Knowledge Base";
    if (path.includes("/project-selection"))
      return "Client & Solution Selection";
    if(path.includes('/chat-history')) return 'Chat History';
    if(path.includes("/chat"))
      return "EY Jarvis"
    if(path.includes("/admin-management"))  return "Admin Management"
    if(path.includes("/usage-analytics"))  return "Usage Analytics"
    return "Dashboard"; // Default title
  };


  const showClientButtons = path.includes("/knowledge-base") || 
    path.includes('/chat-history') ||
    path.includes("/chat") ? true : false;

  const handleActiveClient = (e) => {
    console.log('handleActiveClient', e.target.name)
    dispatch(resetSession())
    dispatch(setActiveClient(e.target.name));
    
  }

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        textAlign: "center",
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {getHeaderTitle()}
        </Typography>
        {/* <Box sx={{
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: 1.5,
          p: 2,
          paddingBottom: 1
        }}>
          <BellIcon />
        </Box> */}
      </Toolbar>
      {/* {showClientButtons && (
        <Box sx={{mb: 7}}>
          <Button 
            variant='outlined' 
            sx={styles.clientBtn} 
            onClick={handleActiveClient}
            className={activeClient === 'Client 1' ? 'active' : ''}
            name='Client 1'
          >
            Client 1
          </Button>
          <Button 
            variant='outlined' 
            sx={styles.clientBtn} 
            onClick={handleActiveClient}
            name='Client 2'
            className={activeClient === 'Client 2' ? 'active' : ''}
          >
            Client 2
          </Button>
          <Button 
            variant='outlined' 
            sx={styles.clientBtn} 
            onClick={handleActiveClient}
            name='Client 3'
            className={activeClient === 'Client 3' ? 'active' : ''}
          >
            Client 3
          </Button>
          <Button 
            variant='outlined' 
            sx={styles.clientBtn} 
            onClick={handleActiveClient}
            name='Client 4'
            className={activeClient === 'Client 4' ? 'active' : ''}
          >
            Client 4
          </Button>
        </Box>
      )} */}
    </AppBar>
  );
};

const AppLayout = () => {
  const location = useLocation();
  const [notificationModal, setNotificationModal] = useState(false);
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          width: "calc(100% - 300px)",
          backgroundColor: "#f7f8fa",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <Box sx={{ 
          flexGrow: 1, 
          overflow: "auto", 
          padding: 3,
          ...(location.pathname.includes('/landing') && { display: 'flex' }),
        }}>
          <Outlet />
          {location.pathname.includes("/landing") && (
            <Box sx={{position: 'absolute', bottom: 10, right: 10}}>
              <EYLogo />
            </Box>
          )}
          <NotificationDialog open={notificationModal}>
            <DialogTitle>
              Notifications
            </DialogTitle>
            <IconButton
              aria-label="close"
              //onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 0,
                top: 8,
                color: theme.palette.secondary.main,
              }}
            >
              <CloseIcon sx={{marginRight: '0px'}}/>
            </IconButton>
            <DialogContent dividers>
              <ListItem>
                <ListItemText 
                  primary="One file was added"
                  secondary="22 minutes ago"
                />
              </ListItem>
              <Divider variant="inset" component="div" />
              <ListItem>
                <ListItemText 
                  primary="Two files were added"
                  secondary="30 minutes ago"
                />
              </ListItem>
              {/* <ListItem>
                <ListItemText 
                  primary="One file was added"
                />
              </ListItem>
              <Divider variant="inset" component="div" />
              <ListItem>
                <ListItemText 
                  primary="Two files were added"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="One file was added"
                />
              </ListItem>
              <Divider variant="inset" component="div" />
              <ListItem>
                <ListItemText 
                  primary="Two files were added"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="One file was added"
                />
              </ListItem>
              <Divider variant="inset" component="div" />
              <ListItem>
                <ListItemText 
                  primary="Two files were added"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="One file was added"
                />
              </ListItem>
              <Divider variant="inset" component="div" />
              <ListItem>
                <ListItemText 
                  primary="Two files were added"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="One file was added"
                />
              </ListItem>
              <Divider variant="inset" component="div" />
              <ListItem>
                <ListItemText 
                  primary="Two files were added Two files were added Two files were added Two files were added Two files were added"
                />
              </ListItem> */}
            </DialogContent>
          </NotificationDialog>
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
