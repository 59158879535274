import React, { createContext, useContext, useState } from 'react';

// Create context
const QueryIdContext = createContext();

// Create a provider component
export const QueryIdProvider = ({ children }) => {
  const [queryId, setQueryId] = useState('');

  return (
    <QueryIdContext.Provider value={{ queryId, setQueryId }}>
      {children}
    </QueryIdContext.Provider>
  );
};

// Custom hook for easier access
export const useQueryId = () => {
  return useContext(QueryIdContext);
};
