import { Dialog, styled } from '@mui/material'

export const NotificationDialog = styled(Dialog)(({theme}) => ({
    '&.MuiDialog-root': {
        left: 'unset',
    },
    '& .MuiDialog-container': {
        width: '300px',
        justifyContent: 'unset',
        WebkitJustifyContent: 'unset',
        alignItems: 'unset',
        WebkitAlignItems: 'unset',
    },
    '& .MuiDialogTitle-root': {
        backgroundColor: theme.palette.sop.black,
        color: theme.palette.secondary.main
    },
    '& .MuiDialog-paper': {
        margin: '75px 20px 32px',
        width: '100%'
    }
}))

// export const CustomButton = styled(Button)((theme) => ({
//     color: theme.primary.main,
// }))

export const styles = {
    clientBtn: (theme) => ({
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
        p: '10px 50px',
        mr: 5,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    })
}
