import React, { Suspense, lazy, useEffect, useRef } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import CONFIG from "../config.js";
import CircularProgress from "@mui/material/CircularProgress";
import AppLayout from "../components/AppLayout/AppLayout.jsx";
import ADTokenPage from "../components/AdTokenPage/AdTokenPage.js";
import UnauthorizedPage from "../pages/401/UnauthorizedPage.jsx";
import ForbiddenPage from "../pages/403/ForbiddenPage.jsx";
import NotFoundPage from "../pages/404/NotFoundPage.jsx";
import ServerErrorPage from "../pages/500/ServerErrorPage.jsx";
import {
  CHAT_PAGE_ROUTE,
  KNOWLEDGE_BASE_ROUTE,
  LOGIN_PAGE_ROUTE,
  PROJECT_SELECTION_ROUTE,
  ERROR_PAGE_401_ROUTE,
  ERROR_PAGE_403_ROUTE,
  ERROR_PAGE_404_ROUTE,
  ERROR_PAGE_500_ROUTE,
  AD_TOKEN_PAGE_ROUTE,
  CHAT_HISTORY_PAGE_ROUTE,
  ADMIN_MANAGEMENT_PAGE_ROUTE,
  USAGE_ANALYTICS_PAGE_ROUTE
} from "../constants/routeConstants.js";

import { QueryIdProvider } from "../context/QueyIdContext.js"

const HomePage = lazy(() => import("../pages/HomePage/HomePage.jsx"));

const ChatWindowPage = lazy(() =>
  import("../pages/ChatWindowPage/ChatWindow.jsx")
);
const Login = lazy(() => import("../components/Login/Login.jsx"));
const ProjectSelection = lazy(() =>
  import("../pages/ProjectSelection/ProjectSelection.jsx")
);
const KnowledgeBase = lazy(() =>
  import("../pages/KnowledgeBase/KnowledgeBase.jsx")
);

const ChatHistoryPage = lazy(() => import('../pages/ChatHistoryPage/ChatHistoryPage.jsx'));
const AdminManagementPage = lazy(() => import('../pages/AdminManagementPage/AdminManagementPage.jsx'));
const UsageAnalyticsPage = lazy(() => import('../pages/UsageAnalyticsPage'));

const Loader = () => <CircularProgress size={50} />;

const ProtectedRoute = ({ children }) => {
  const refreshingToken = useRef(null);
  useEffect(() => {
    const accessTokenValidyCheck = async () => {
      const access_token_exp = Cookies.get("access_token_exp");

      if (access_token_exp && refreshingToken.current === false) {
        const current_unix_time = Math.floor(Date.now() / 1000);

        if (access_token_exp <= current_unix_time + 900) {
          console.log("Access Token about to expire. Going to refresh");
          refreshingToken.current = true;
          try {
            const response = await fetch(
              `${CONFIG.BASE_URL}/api/v1/token/refresh`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            const resp_json = await response.json();

            if (!response.ok) {
              throw new Error(`${resp_json.message}`);
            }

            if (resp_json.status === "success") {
              console.log("Access token refreshed successfully");
              refreshingToken.current = false;
            } else {
              console.log(
                "Failed to refresh access token: " + resp_json.message
              );
              refreshingToken.current = false;
            }
          } catch (error) {
            console.log("Failed to refresh access token: " + error);
            refreshingToken.current = false;
          }
        }
      }
    };

    const intervalId = setInterval(accessTokenValidyCheck, 300 * 1000);

    return () => clearInterval(intervalId);
  }, []);
  if (
    Cookies.get("access_token_exp") &&
    parseInt(Cookies.get("access_token_exp")) > Math.floor(Date.now() / 1000)
  ) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <AppLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <ChatWindowPage />
          </Suspense>
        ),
      },
      {
        path: PROJECT_SELECTION_ROUTE,
        element: (
          <Suspense fallback={<Loader />}>
            <ProjectSelection />
          </Suspense>
        ),
      },
      {
        path: KNOWLEDGE_BASE_ROUTE,
        element: (
          <Suspense fallback={<Loader />}>
            <KnowledgeBase />
          </Suspense>
        ),
      },
      {
        path: CHAT_PAGE_ROUTE,
        element: (
          <Suspense fallback={<Loader />}>
            <QueryIdProvider>
              <ChatWindowPage />
            </QueryIdProvider>
          </Suspense>
        ),
      },
      {
        path: CHAT_HISTORY_PAGE_ROUTE,
        element: (
          <Suspense fallback={<Loader />}>
            <QueryIdProvider>
              <ChatHistoryPage />
            </QueryIdProvider>
          </Suspense>
        ),
      },
      {
        path: ADMIN_MANAGEMENT_PAGE_ROUTE,
        element: (
          <Suspense fallback={<Loader />}>
            <AdminManagementPage />
          </Suspense>
        ),
      },
      {
        path: USAGE_ANALYTICS_PAGE_ROUTE,
        element: (
          <Suspense fallback={<Loader />}>
            <UsageAnalyticsPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: LOGIN_PAGE_ROUTE,
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: AD_TOKEN_PAGE_ROUTE,
    element: (
      <Suspense fallback={<Loader />}>
        <ADTokenPage />
      </Suspense>
    ),
  },
  {
    path: ERROR_PAGE_401_ROUTE,
    element: <UnauthorizedPage />,
  },
  {
    path: ERROR_PAGE_403_ROUTE,
    element: <ForbiddenPage />,
  },
  {
    path: ERROR_PAGE_404_ROUTE,
    element: <NotFoundPage />,
  },
  {
    path: ERROR_PAGE_500_ROUTE,
    element: <ServerErrorPage />,
  },
  {
    path: "*",
    element: <Navigate to="/chat" />,
  },
]);

export default router;
